import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { ImageInterface, SanityLabelsInterface } from "../../types/SanityTypes";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { Link } from "../Link";
import Section from "../Section";
import { LocalizedContext } from "../../services/LocalizedContextService";

import "./styles.scss";
import ProductCardPromo from "../ProductCardPromo";

import AddToCartAlert from "../AddtoCartAlert";

interface ProductListingBlockWithPromoInterface {
  _id: string;
  i18n_lang: string;
  heading?: string;
  products?: [
    {
      name: string;
      price: number;
      inStock: boolean;
      images: [ImageInterface];
      productCategory: {
        name: string;
      };
    }
  ];
  bgColor?: {
    title: string;
    value: string;
  };
  isHomePageVersion?: boolean;
}

const ProductListingBlockWithPromo: FunctionComponent<ProductListingBlockWithPromoInterface> = props => {
  const { products, isHomePageVersion, bgColor, heading } = props;
  const [width, setWidth] = useState(0);
  const { sanityLabels, sanitySiteSettings } = useContext(LocalizedContext);
  const { productLabels } = sanityLabels as SanityLabelsInterface;

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [linkToProduct, setLinkToProduct] = useState("");

  const productItems = products || [];

  const breakPointsOptions = {
    centeredSlides: false,
    initialSlide: 0,
    scrollbar: {
      draggable: true
    },
    spaceBetween: 24
  };

  const setBreakPoints = {
    375: {
      ...breakPointsOptions,
      spaceBetween: 16,
      slidesPerView: 1.3
    },
    768: {
      ...breakPointsOptions,
      slidesPerView: 2
    },
    1024: {
      ...breakPointsOptions,
      slidesPerView: 3
    },
    1440: {
      ...breakPointsOptions,
      slidesPerView: 4
    }
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Section
      sectionClassName={`c-productListingBlockWithPromoWithPromo ${props._id} ${
        isHomePageVersion ? "home-page-version" : ""
      }`}
      title={heading}
      theme={bgColor?.title}
    >
      {productItems && productItems.length ? (
        <Swiper
          key="productWithPromo"
          preloadImages={true}
          centeredSlides={true}
          centeredSlidesBounds={true}
          initialSlide={0}
          slidesPerView={"auto"}
          spaceBetween={20}
          simulateTouch={true}
          lazy={true}
          freeMode={true}
          loopedSlides={4}
          loop={false}
          scrollbar={{ draggable: false }}
          preventClicks={true}
          breakpoints={setBreakPoints}
          watchSlidesProgress={false}
          grabCursor={true}
          modules={[FreeMode]}
          className={productItems.length <= 3 && width > 768 ? "swiper-no-swiping" : ""}
        >
          {productItems.map((item: any, index: number) => {
            return (
              <SwiperSlide
                className="swiper-slide-animation"
                key={`slider-${index}`}
                data-testid={`playlist-slide-${index}`}
                style={{ width: "inherit", height: "auto" }}
              >
                <Link to={item.product.slug.current} className="c-productListingBlockWithPromo__productItem">
                  <ProductCardPromo
                    index={index}
                    id={item.product._id}
                    productName={item.product.name}
                    image={item.product.images[0]}
                    customImage={item.customImage}
                    hoverImage={item.hoverImage}
                    price={item.product.price}
                    discountedPrice={item.product.promotion?.price}
                    brandName={sanitySiteSettings?.brandName}
                    productLabels={productLabels}
                    orderable={item.product.inStock}
                    isHomeVersion={isHomePageVersion}
                    isPromoSlide={(index + 1) % 4 === 0 ? true : false}
                    setAlertMessage={setAlertMessage}
                    setShowAlert={setShowAlert}
                    productLink={item.product.slug.current}
                    setLinkToProduct={setLinkToProduct}
                  />
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : null}
      {showAlert && <AddToCartAlert message={alertMessage} setShowAlert={setShowAlert} linkToProduct={linkToProduct} />}
    </Section>
  );
};

export default ProductListingBlockWithPromo;
