import React, { FunctionComponent, MouseEvent, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Button from "../Button";
import { Price } from "../Price";
import { ImageInterface } from "../../types/SanityTypes";
import { LocalizedContext } from "../../services/LocalizedContextService";
import EcommerceContext from "../../services/EcommerceContextService";
import { addProduct } from "../../services/salesforce/Bag";
import SanityImage from "gatsby-plugin-sanity-image";
import { useMutation } from "react-query";
import { productSearchById } from "../../services/salesforce/Product";
import LoaderIcon from "../../images/icons/loader";
import { scAddEvent } from "../../analytics/scAddProduct";

import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";

export interface ProductCardPromoInterface {
  index?: number;
  id: string;
  productName: string;
  price: number;
  discountedPrice?: number;
  brandName?: string;
  productLabels: {
    addToBag: string;
    added?: string;
    available?: string;
    soldOut?: string;
  };
  productCategory?: string;
  orderable: boolean;
  image?: ImageInterface;
  customImage?: ImageInterface;
  hoverImage?: ImageInterface;
  isHomeVersion?: boolean;
  isPromoSlide: boolean;
  setAlertMessage: (alertMessaage: string) => void;
  setShowAlert: (showAlert: boolean) => void;
  productLink: string;
  setLinkToProduct: (linkToProduct: string) => void;
}

const ProductCardPromo: FunctionComponent<ProductCardPromoInterface> = ({
  id,
  productName,
  price,
  brandName,
  productLabels,
  productCategory,
  image,
  customImage,
  hoverImage,
  orderable,
  isHomeVersion,
  isPromoSlide,
  setAlertMessage,
  setShowAlert,
  productLink,
  setLinkToProduct,
  index
}) => {
  const { sanitySiteSettings } = useContext(LocalizedContext);
  const { setBagItems } = useContext(EcommerceContext);

  const [itemOrderable, setItemOrderable] = useState(orderable);

  const handleClick = async (event: MouseEvent<HTMLButtonElement, Event>) => {
    event.preventDefault();
    scAddEvent(id, productName, productCategory || "", brandName || "", price, 1, "ProductCardPromo", index);
    try {
      const response = await addProduct(id);
      const { quantity, title, basketId } = response?.data;
      if (basketId) {
        setBagItems(response?.data);
        setAlertMessage("Product Added to the Bag");
      }
      if (quantity && title) {
        setAlertMessage(title);
      }
    } catch (err) {
      console.log("error..", err);
      setAlertMessage("Failed to add product to the Bag");
    } finally {
      setLinkToProduct(productLink);
      setShowAlert(true);
    }
  };

  const { addToBag, soldOut } = productLabels;
  const productImage = image ? image.asset : sanitySiteSettings._rawProductFallbackImage?.asset;

  const {
    data,
    error,
    isLoading,
    mutate: searchProduct
  } = useMutation({
    mutationFn: productSearchById,
    retry: 1,
    onSuccess: response => {
      const { data = {} } = response || {};
      if (data && data.id) {
        setItemOrderable(data.inventory.orderable);
      }
    },
    mutationKey: id
  });

  useEffect(() => {
    searchProduct(id);
  }, []);

  return (
    <Card className="product-card-promo h-100">
      <SanityImage
        width={400}
        height={isPromoSlide ? 600 : 400}
        htmlWidth={400}
        htmlHeight={isPromoSlide ? 600 : 400}
        className="img-fluid"
        sizes={IMAGE_SIZES.HALF}
        asset={customImage ? customImage.asset : productImage}
        alt={image ? image.alt : productName}
      />
      {hoverImage && (
        <SanityImage
          width={400}
          height={isPromoSlide ? 600 : 400}
          htmlWidth={400}
          htmlHeight={isPromoSlide ? 600 : 400}
          className="img-fluid hover-image"
          sizes={IMAGE_SIZES.HALF}
          asset={hoverImage.asset}
          alt={image ? image.alt : productName}
        />
      )}
      {!isPromoSlide && (
        <>
          <Card.Body className="text-center product-card-promo__content d-flex flex-column">
            <h3 className="product-card__title">{productName}</h3>
            <Button
              className="product-card-promo__add-to-cart"
              variant={isHomeVersion ? "octonary" : "nonary"}
              form={"br-50"}
              onClick={handleClick}
              disabled={!itemOrderable}
              title={!itemOrderable ? `Product ${productName} is not orderable` : "Click to add product"}
            >
              <Price price={price} />
              {itemOrderable ? <span>+ {addToBag}</span> : <span>{soldOut}</span>}
              {isLoading && <LoaderIcon width={25} height={25} className="loaderIcon" />}
            </Button>
          </Card.Body>
        </>
      )}
    </Card>
  );
};

export default ProductCardPromo;
